import styled from "styled-components";

export const Container = styled.div`
  width: 75%;
  margin: auto;
  padding-top: 150px;
  font-weight: 300;
  font-size: 20px;
  line-height: 170%;


  h1 {
    font-size: 80px;
    line-height: 150%;
    margin: 0 0 140px 0;
  }

  h2 {
    margin: 2.5em 0 .8em 0;
    font-size: 2em;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -.05em;
  }

  h3 {
    margin: 2em 0 .8em 0;
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: -.04em;
    line-height: 100%;
  }

  a {
    color: #000;
  }

  b {
    text-decoration: underline;
  }
  ul {
    padding-left: 75px;
    margin-top: 25px;
  }

  .linkhold {
    display: inline;
  }
`;